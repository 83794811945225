import React from 'react'
import fImg from '../../images/fun-fact-shape.png'
import CountUp, { useCountUp } from 'react-countup';

const FunFact = (props) => {


    useCountUp({
        end: '56656',
        ref: 'counter',
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
    });


    return (
        <section className="gl-fun-fact-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="gl-fun-fact-grids clearfix">
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-1"><CountUp end="12" enableScrollSpy /></h3>
                                    <p>years of experiecne</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-2"><CountUp end="100" enableScrollSpy />%</h3>
                                    <p>customer
                                        satisfaction</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-3"><CountUp end="72" enableScrollSpy />+</h3>
                                    <p>clients on
                                        world wide</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-4"><CountUp end="200" enableScrollSpy />+</h3>
                                    <p>complete
                                        customer projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fun-fact-shape">
                <img src={fImg} alt="" />
            </div>
            <span id="counter" className='d-none' />
        </section>
    )
}

export default FunFact;