import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'

import bg from '../../images/testimonial/bg-img.png'
import quote from '../../images/testimonial/quotation.png'
import tshape from '../../images/testimonial/shape.png'


const testimonial = [
    {
        id: '01',
        tImg: ts1,
        Des: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially and unchanged. It was popularised in the 1960s with the release of Letraset sheets and containing Lorem Ipsum passages, and more recently with desktop publishing.",
        Title: 'Cathi Falcon',
        Sub: "Founder",
    },
    {
        id: '02',
        tImg: ts2,
        Des: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially and unchanged. It was popularised in the 1960s with the release of Letraset sheets and containing Lorem Ipsum passages, and more recently with desktop publishing.",
        Title: 'Harry Abraham',
        Sub: "SCG First Company",
    },
    {
        id: '03',
        tImg: ts3,
        Des: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially and unchanged. It was popularised in the 1960s with the release of Letraset sheets and containing Lorem Ipsum passages, and more recently with desktop publishing.",
        Title: 'Benjir Walton',
        Sub: "Merketer",
    },
    {
        id: '04',
        tImg: ts1,
        Des: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially and unchanged. It was popularised in the 1960s with the release of Letraset sheets and containing Lorem Ipsum passages, and more recently with desktop publishing.",
        Title: 'Cathi Falcon',
        Sub: "Founder",
    },
]

const Testimonial = () => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();


    return (
        <section className="gl-testimonial-section section-padding">
            <div className="container">
                <div className="gl-testimonial-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-12">
                            <div className="testimonial-left">
                                <div className="testimonial-left-inner">
                                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} dots={false} arrows={false} fade={true}>
                                        {
                                            testimonial.map((tesmnl, tsm) => (
                                                <div className="testimonial-img" key={tsm}>
                                                    <img src={tesmnl.tImg} alt="" />
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                    <div className="bg-img-s1">
                                        <img src={bg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="gl-testimonial-items">
                                <Slider
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={1}
                                    dots={true}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >
                                    {
                                        testimonial.map((tesmnl, tsm) => (
                                            <div className="gl-testimonial-item" key={tsm}>
                                                <div className="gl-testimonial-text">
                                                    <p>“ {tesmnl.Des} ”</p>
                                                    <div className="gl-testimonial-text-btm">
                                                        <h3>{tesmnl.Title}</h3>
                                                        <span>{tesmnl.Sub}</span>
                                                    </div>
                                                </div>
                                                <div className="quotation">
                                                    <img src={quote} alt="" />
                                                </div>
                                            </div>
                                        ))
                                    }

                                </Slider>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="left-shape">
                <img src={tshape} alt="" />
            </div>
        </section>
    );
}

export default Testimonial;