import React from "react";
import { Link } from 'react-scroll'

import hero1 from '../../images/slider/slide-1.png'

import bshape1 from '../../images/slider/border-shape-1.png'
import bshape2 from '../../images/slider/border-shape-2.png'

import shape1 from '../../images/slider/shape-1.png'
import shape2 from '../../images/slider/shape-2.png'
import shape3 from '../../images/slider/shape-3.png'
import shape4 from '../../images/slider/shape-4.png'
import shape5 from '../../images/slider/shape-5.png'
import shape6 from '../../images/slider/shape-6.png'
import shape7 from '../../images/slider/shape-7.png'
import shape8 from '../../images/slider/shape-8.png'



const Hero = () => {
    return (
        <section className="static-hero" id="home">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-12">
                                <div className="gl-static-hero-inner">
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h2><span>Franklin</span>
                                            Terry
                                        </h2>
                                    </div>
                                    <div data-swiper-parallax="300" className="slide-sub-title">
                                        <h5>Cretive ui/ux
                                            designer.</h5>
                                    </div>
                                    <div data-swiper-parallax="400" className="slide-text">
                                        <p>Lorem Ipsum has been industry standard.</p>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="slide-btn"><Link to="portfolio" spy={true} smooth={true}  duration={500} className="theme-btn-s2">MY
                                        PORTFOLIO</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="static-hero-right">
                <div className="static-hero-img">
                    <div className="static-hero-img-inner">
                        <img src={hero1} alt=""/>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <svg width="1329" height="938" viewBox="0 0 1329 938" fill="none">
                    <g opacity="0.23" filter="url(#filter0_f_1_400)">
                        <circle cx="447" cy="186" r="538" fill="url(#paint0_linear_1_4008)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1_400" x="-435" y="-696" width="1764" height="1764"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="172" result="effect1_foregroundBlur_1_400" />
                        </filter>
                        <linearGradient id="paint0_linear_1_4008" x1="447" y1="-352" x2="447" y2="724"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#FA00FF" />
                            <stop offset="1" stopColor="#FFDA27" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="shape-2">
                <svg width="1316" height="938" viewBox="0 0 1316 938" fill="none">
                    <g opacity="0.23" filter="url(#filter0_f_1_401)">
                        <circle cx="882" cy="633" r="538" fill="url(#paint0_linear_1_4010)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1_401" x="0" y="-249" width="1764" height="1764"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="172" result="effect1_foregroundBlur_1_401" />
                        </filter>
                        <linearGradient id="paint0_linear_1_4010" x1="882" y1="95" x2="882" y2="1171"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="1" stopColor="#FA00FF" />
                            <stop offset="1" stopColor="#FFDA27" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="border-shape-1">
                <img src={bshape1} alt=""/>
            </div>
            <div className="border-shape-2">
                <img src={bshape2} alt=""/>
            </div>
            <div className="slider-single-img-1">
                <img src={shape1} alt=""/>
            </div>
            <div className="slider-single-img-2">
                <img src={shape2} alt=""/>
            </div>
            <div className="slider-single-img-3">
                <img src={shape3} alt=""/>
            </div>
            <div className="slider-single-img-4">
                <img src={shape4} alt=""/>
            </div>
            <div className="slider-single-img-5">
                <img src={shape5} alt=""/>
            </div>
            <div className="slider-single-img-6">
                <img src={shape6} alt=""/>
            </div>
            <div className="slider-single-img-7">
                <img src={shape7} alt=""/>
            </div>
            <div className="slider-single-img-8">
                <img src={shape8} alt=""/>
            </div>
        </section>
    )
}

export default Hero;