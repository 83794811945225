import React from 'react'
import { Link } from 'react-scroll'
import Logo from '../../images/logo.svg'
import fshape1 from '../../images/footer/shape-1.png'
import fshape2 from '../../images/footer/shape-2.png'
import fshape3 from '../../images/footer/shape-3.png'
import fshape4 from '../../images/footer/shape-4.png'
import fshape5 from '../../images/footer/shape-5.png'
import fshape6 from '../../images/footer/shape-6.png'
import fshape7 from '../../images/footer/shape-7.png'
import fshape8 from '../../images/footer/shape-8.png'
import fshape9 from '../../images/footer/shape-9.png'


const SubmitHandler = (e) => {
    e.preventDefault()
}

const Footer = (props) => {
    return (
        <footer className="gl-site-footer">
            <div className="shape-1">
                <svg width="715" height="610" viewBox="0 0 715 610" fill="none">
                    <g opacity="0.12" filter="url(#filter0_f_1_8)">
                        <circle cx="125" cy="24" r="246" fill="url(#paint0_linear_1_85)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1_8" x="-465" y="-566" width="1180" height="1180"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="172" result="effect1_foregroundBlur_1_8" />
                        </filter>
                        <linearGradient id="paint0_linear_1_85" x1="125" y1="-222" x2="125" y2="270"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#FA00FF" />
                            <stop offset="1" stopColor="#FFDA27" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="shape-left-img-1">
                <img src={fshape1} alt=""/>
            </div>
            <div className="shape-left-img-2">
                <img src={fshape2} alt=""/>
            </div>
            <div className="shape-left-img-3">
                <img src={fshape3} alt=""/>
            </div>
            <div className="shape-left-img-4">
                <img src={fshape4} alt=""/>
            </div>
            <div className="gl-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog"/>
                                </div>
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, make
                                    specimen book.</p>

                                <h3>Contact:</h3>
                                <ul>
                                    <li><span>Call:</span> +88 659 789 874</li>
                                    <li><span>E-mail:</span> infoportx@gmail.com</li>
                                    <li><span>Website:</span> www.portx24.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>quick links:</h3>
                                </div>
                                <ul>
                                    <li><Link to="home" spy={true} smooth={true}  duration={500}><i className="ti-angle-right"></i> Home</Link></li>
                                    <li><Link to="blog" spy={true} smooth={true}  duration={500}><i className="ti-angle-right"></i> Blog Post</Link></li>
                                    <li><Link to="pricing" spy={true} smooth={true}  duration={500}><i className="ti-angle-right"></i> Pricing</Link></li>
                                    <li><Link to="contact" spy={true} smooth={true}  duration={500}><i className="ti-angle-right"></i> Contact us</Link></li>
                                    <li><Link to="blog" spy={true} smooth={true}  duration={500}><i className="ti-angle-right"></i> Recent Post</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget blog-widget">
                                <div className="widget-title">
                                    <h3>Recent Post:</h3>
                                </div>
                                <ul>
                                    <li>
                                        <p><Link to="blog" spy={true} smooth={true}  duration={500}>The standard chunk of used since the
                                            interested.</Link></p>
                                        <span><i className="ti-calendar"></i> 10.09.2022</span>
                                    </li>
                                    <li>
                                        <p><Link to="blog" spy={true} smooth={true}  duration={500}>Have evolved over the years sometimes
                                            accident.</Link></p>
                                        <span><i className="ti-calendar"></i> 10.09.2022</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>newsletter:</h3>
                                </div>
                                <p>Lorem Ipsum has been the industry standard text ever since the printer took.</p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="Enter your email"
                                            required=""/>
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit"><i className="fa fa-paper-plane"
                                            aria-hidden="true"></i></button>
                                    </div>
                                </form>

                                <ul>
                                    <li>
                                        <Link to="/">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="ti-google"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gl-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2022 portx by <a href="index.html">Geniuslab</a>. All
                                Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-2">
                <svg width="768" height="610" viewBox="0 0 768 610" fill="none">
                    <g opacity="0.12" filter="url(#filter0_f_1_9)">
                        <circle cx="641.5" cy="509.5" r="297.5" fill="url(#paint0_linear_1_96)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1_9" x="0" y="-132" width="1283" height="1283"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="172" result="effect1_foregroundBlur_1_9" />
                        </filter>
                        <linearGradient id="paint0_linear_1_96" x1="641.5" y1="212" x2="641.5" y2="807"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#FA00FF" />
                            <stop offset="1" stopColor="#FFDA27" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="shape-right-img-1">
                <img src={fshape5} alt=""/>
            </div>
            <div className="shape-right-img-2">
                <img src={fshape6} alt=""/>
            </div>
            <div className="shape-right-img-3">
                <img src={fshape7} alt=""/>
            </div>
            <div className="shape-right-img-4">
                <img src={fshape8} alt=""/>
            </div>
            <div className="shape-right-img-5">
                <img src={fshape9} alt=""/>
            </div>
        </footer>
    )
}

export default Footer;