import React, { useState } from 'react';
import Slider from "react-slick";
import { Button } from '@mui/material'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blogs from '../../api/blogs'
import BlogSingle from "../BlogSingle";
import bShape1 from "../../images/blog/shape-1.png";
import bShape2 from "../../images/blog/shape-2.png";
import bShape3 from "../../images/blog/shape-3.png";
import bShape4 from "../../images/blog/shape-4.png";
import bShape5 from "../../images/blog/shape-5.png";
import bShape6 from "../../images/blog/shape-6.png";
import bShape7 from "../../images/blog/shape-7.png";
import bShape8 from "../../images/blog/shape-8.png";

const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const BlogSection = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }
    return (

        <section className="gl-blog-section section-padding" id='blog'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-md-10 col-12">
                        <div className="gl-section-title">
                            <h2>latest news & article</h2>
                        </div>
                    </div>
                </div>
                <div className="blog-wrap">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="blog-grids blog-slider">
                                <Slider {...settings}>
                                    {blogs.map((blog, bl) => (
                                        <div className="grid" key={bl}>
                                        <div className="image">
                                            <img src="assets/images/blog/bg-shape.png" alt=""/>
                                        </div>
                                        <div className="content">
                                            <span>{blog.create_at}</span>
                                            <h2>{blog.title}</h2>
                                            <p>{blog.description}</p>
                                            <Button
                                                className="theme-btn-s4"
                                                onClick={() => handleClickOpen(blog)}>
                                                read more <i className="ti-arrow-right"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={bShape1} alt="" />
            </div>
            <div className="shape-2">
                <img src={bShape2} alt="" />
            </div>
            <div className="shape-3">
                <img src={bShape3} alt="" />
            </div>
            <div className="shape-4">
                <img src={bShape4} alt="" />
            </div>
            <div className="shape-5">
                <img src={bShape5} alt="" />
            </div>
            <div className="shape-6">
                <img src={bShape6} alt="" />
            </div>
            <div className="shape-7">
                <img src={bShape7} alt="" />
            </div>
            <div className="shape-8">
                <img src={bShape8} alt="" />
            </div>
            <BlogSingle open={open} onClose={handleClose} title={state.title} bImg={state.screens} create_at={state.create_at} author={state.author} comment={state.comment} />
        </section>
    );
}

export default BlogSection;