import React, { useState } from 'react';
import { Button } from '@mui/material'
import Services from '../../api/service'
import ServiceSingle from '../ServiceSingle';
import shape1 from '../../images/service/shape-1.png'
import shape2 from '../../images/service/shape-2.png'
import shape3 from '../../images/service/shape-3.png'
import shape4 from '../../images/service/shape-4.png'
import shape5 from '../../images/service/shape-5.png'
import shape6 from '../../images/service/shape-6.png'

const ServiceSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <section className="gl-service-section section-padding" id='service'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-md-12 col-12">
                        <div className="gl-section-title">
                            <h2>High-Quality services</h2>
                        </div>
                    </div>
                </div>
                <div className="service-wrap">
                    <div className="row">
                        {Services.slice(0, 3).map((service, srv) => (
                            <div className="col-lg-4 col-md-6 col-12" key={srv}>
                                <div className="service-grid">
                                    <div className="grid-inner">
                                        <div className="image">
                                            <img src={service.sImg} alt=""/>
                                                <div className="shape">
                                                    <svg width="160" height="160" viewBox="0 0 160 160" fill="none">
                                                        <circle cx="80" cy="80" r="80" fill="url(#paint0_linear_1_3371)" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_1_3371" x1="-131" y1="-183"
                                                                x2="125" y2="141" gradientUnits="userSpaceOnUse">
                                                                <stop offset="0" stopColor="white" />
                                                                <stop offset="1" stopColor="#181520" stopOpacity="0" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>

                                        </div>
                                        <div className="content">
                                            <h2>{service.sTitle}</h2>
                                            <p>{service.description}</p>
                                            <Button
                                                className="btn"
                                                onClick={() => handleClickOpen(service)}>
                                                <i className="ti-arrow-top-right"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={shape1} alt=""/>
            </div>
            <div className="shape-2">
                <img src={shape2} alt=""/>
            </div>
            <div className="shape-3">
                <img src={shape3} alt=""/>
            </div>
            <div className="shape-4">
                <img src={shape4} alt=""/>
            </div>
            <div className="shape-5">
                <img src={shape5} alt=""/>
            </div>
            <div className="shape-6">
                <img src={shape6} alt=""/>
            </div>
            <div className="shape-7">
                <img src={shape4} alt=""/>
            </div>
            <div className="shape-8">
                <img src={shape2} alt=""/>
            </div>
            <ServiceSingle open={open} onClose={handleClose} title={state.sTitle} dImg={state.ssImg} sImg1={state.ssImg2} sImg2={state.ssImg3} />
        </section>
    );
}

export default ServiceSection;